import { createWebHistory, createRouter} from 'vue-router';
import BaseIframeView from "@/components/BaseIframeView.vue";

import ProposalView from "@/views/ProposalView.vue";
import ProposalCreateView from "@/views/proposal/proposal-create/ProposalCreateView.vue";
import NewsFeedView from "@/views/NewsFeedView.vue";
import OrganizationView from "@/views/OrganizationView.vue";
import UserMyDesktopView from "@/views/UserMyDesktopView.vue";
import AboutView from "@/views/AboutView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import UserTermsView from "@/views/UserTermsView.vue";
import CookiePolicyView from "@/views/CookiePolicyView.vue";
import BecomeManagerView from "@/views/BecomeManagerView.vue";
import ManagerLoungeView from "@/views/manager/ManagerLoungeView.vue";
import ManagerKnowledgeAreaView from "@/views/manager/ManagerKnowledgeAreaView.vue";
import ManagerView from "@/views/manager/ManagerView.vue";


const router = createRouter({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: NewsFeedView,
            framePath: '/',
        },
        {
            path: '/proposal/:id/:version?',
            name: 'ProposalView',
            component: ProposalView,
            framePath: '/proposal/'
        },
        {
            path: '/opinion/:id/:version?',
            name: 'OpinionView',
            component: ProposalView,
            framePath: '/opinion/'
        },
        {
            path: '/showcreateproposal',
            name: 'ProposalCreateView',
            component: ProposalCreateView,
        },
        {
            path: '/org/:id',
            name: 'OrganizationView',
            component: OrganizationView,
        },
        {
            path: '/user/:id',
            name: 'UserView',
            component: UserMyDesktopView,
            framePath: '/user/'
        },
        {
            path: '/:id/mydesktop',
            name: 'UserMyDesktopView',
            component: UserMyDesktopView,
        },
        {
            path: '/about',
            name: 'About',
            component: AboutView,
            framePath: '/about',
        },
        {
            path: '/privacypolicy',
            component: PrivacyPolicyView,
            framePath: '/privacypolicy',
        },
        {
            path: '/userterms',
            component: UserTermsView,
            framePath: '/userterms',
        },
        {
            path: '/cookiepolicy',
            component: CookiePolicyView,
            framePath: '/cookiepolicy',
        },
        {
            name: 'ManagerView',
            path: '/manager',
            component: ManagerView,
            framePath: '/manager',
        },
        {
            name: 'ManagerViewOrg',
            path: '/manager/org',
            component: ManagerView,
            framePath: '/manager/org',
        },
        {
            name: 'ManagerViewForum',
            path: '/manager/forum',
            component: ManagerView,
            framePath: '/manager/forum',
        },
        {
            name: 'ManagerViewElections',
            path: '/manager/elections',
            component: ManagerView,
        },
        {
            name: 'ManagerCriticalEvents',
            path: '/manager/criticalevents',
            component: ManagerView,
            framePath: '/manager/criticalevents',
        },
        {
            name: 'ManagerContentReviews',
            path: '/manager/contentreviews',
            component: ManagerView,
            framePath: '/manager/contentreviews',
        },
        {
            name: 'ManagerApplications',
            path: '/manager/applications',
            component: ManagerView,
            framePath: '/manager/applications',
        },
        {
            name: 'ManagerViewManagers',
            path: '/manager/managers',
            component: ManagerView,
            framePath: '/manager/managers',
        },
        {
            name: 'ManagerIntegration',
            path: '/manager/integration',
            component: ManagerView,
            framePath: '/manager/integration',
        },
        {
            name: 'ManagerUserTerms',
            path: '/manager/usertermas',
            component: ManagerView,
            framePath: '/manager/usertermas',
        },
        {
            name: 'ManagerAds',
            path: '/manager/ads',
            component: ManagerView,
            framePath: '/manager/ads',
        },
        {
            name: 'ManagerProfile',
            path: '/manager/profile',
            component: ManagerView,
            framePath: '/manager/profile',
        },
        {
            path: '/managerLounge',
            component: ManagerLoungeView,
            framePath: '/managerLounge',
        },
        {
            path: '/managerKnowledgeArea',
            component: ManagerKnowledgeAreaView,
            framePath: '/managerKnowledgeArea',
        },
        {
            path: '/becomeManager',
            component: BecomeManagerView,
            framePath: '/becomeManager',
        },
        {
            path: '/:pathMatch(.*)*',
            component: BaseIframeView
        }
    ],
    history: createWebHistory()
});

export default router;